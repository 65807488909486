import React from "react";

export const Footer = ({info}) => {
  return (
    <>
      <footer id="contact" className="footer" role="contact">
        <div className="footer-top">
          <div className="container text-left">
            <div className="col-md-6">
              <div className="row">
                <div className="footer-col col-md-6">
                  <h5>Адрес</h5>
                  <p>
                    {info.fullAddress}
                  </p>
                </div>
                <div className="footer-col col-md-6">
                  <h5>Телефоны</h5>
                  <p>
                    <a href="tel:+375297396238">{info.phone[0]}</a>
                  </p>
                  <p>
                    <a href="tel:+375236202022">{info.phone[1]}</a>
                  </p>
                  <p>
                    <a href="mailto:profzdravmed@gmail.com">
                      {info.email}
                    </a>
                    <a />
                  </p>
                  <a></a>
                </div>
                <a></a>
              </div>
              <a>
                <div className="row">
                  <div className="col-md-12">
                    <h5>
                      {info.fullCompanyName}
                    </h5>
                    <p>
                      {info.certificateData[0]}
                      <br />
                      {info.certificateData[1]}
                      <br />
                      {info.certificateData[2]}
                      <br />
                    </p>
                  </div>
                </div>
              </a>
            </div>
            <a>
              <div className="col-md-6">
                <div className="row">
                  <div className="footer-col col-md-12">
                    <h5>Карта проезда</h5>
                    <div className="map-container">
                      <iframe
                        className="map-container__map"
                        src="https://yandex.com/map-widget/v1/?um=constructor%3Aa3c242140a4ae18645ca47a9b66ced0a7794356aeb3216cfd8a6bba92a361d93&amp;source=constructor"
                        width="500"
                        height="281"
                        frameborder="0"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <a></a>
        </div>
        <a></a>
        <div className="footer-bottom">
          <a></a>
          <div className="container">
            <a></a>
            <div className="row">
              <a></a>
              <div className="col-md-12 author text-right">
                <a>
                  <span>Разработка и поддержка сайта - </span>
                </a>
                <a href="mailto:ishoomsky@gmail.com">
                  ishoomsky@gmail.com <i className="fa fa-envelope fa-1x" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="hidden">
        <form id="form">
          <fieldset form="form">
            <legend>Заполните данные:</legend>
            <input
              type="hidden"
              name="project_name"
              defaultValue="profzdravmed.by"
            />
            <input
              type="hidden"
              name="form_subject"
              defaultValue="Заявка с сайта"
            />
            <label htmlFor="form-name">
              Ваше имя:
              <input
                type="text"
                id="form-name"
                name="name"
                required
                autoComplete="off"
              />
            </label>
            <label htmlFor="form-phone">
              Ваш телефон:
              <input
                type="text"
                id="form-phone"
                name="phone"
                required
                autoComplete="off"
              />
            </label>
          </fieldset>
          <fieldset form="form">
            <legend>Выберите услуги:</legend>
            <div className="checkbox-layout">
              <input
                type="checkbox"
                id="service-1"
                name="service-1"
                defaultValue="терапия"
              />
              <label htmlFor="service-1">терапия </label>
            </div>
            <div className="checkbox-layout">
              <input
                type="checkbox"
                id="service-2"
                name="service-2"
                defaultValue="УЗИ"
              />
              <label htmlFor="service-2">УЗИ</label>
            </div>
            <div className="checkbox-layout">
              <input
                type="checkbox"
                id="service-3"
                name="service-3"
                defaultValue="эндокринология"
              />
              <label htmlFor="service-3">эндокринология</label>
            </div>
            <div className="checkbox-layout">
              <input
                type="checkbox"
                id="service-4"
                name="service-4"
                defaultValue="педиатрия"
              />
              <label htmlFor="service-4">педиатрия</label>
            </div>
            <div className="checkbox-layout">
              <input
                type="checkbox"
                id="service-5"
                name="service-5"
                defaultValue="офтальмология"
              />
              <label htmlFor="service-5">офтальмология</label>
            </div>
            <div className="checkbox-layout">
              <input
                type="checkbox"
                id="service-6"
                name="service-6"
                defaultValue="массаж"
              />
              <label htmlFor="service-6">массаж</label>
            </div>
            <div className="checkbox-layout">
              <input
                type="checkbox"
                id="service-7"
                name="service-7"
                defaultValue="психотерапия"
              />
              <label htmlFor="service-7">психотерапия</label>
            </div>
            <div className="checkbox-layout">
              <input
                type="checkbox"
                id="service-8"
                name="service-8"
                defaultValue="неврология"
              />
              <label htmlFor="service-8">неврология</label>
            </div>
            <div className="checkbox-layout">
              <input
                type="checkbox"
                id="service-9"
                name="service-9"
                defaultValue="гинекология"
              />
              <label htmlFor="service-9">гинекология</label>
            </div>
            <div className="button-layout">
              <button className="btn  btn-large">Отправить</button>
            </div>
          </fieldset>
        </form>
      </div>
      <div id="scroller">наверх</div>
    </>
  );
};
