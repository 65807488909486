import React from "react";

export const Features = () => {
  return (
    <section id="features" className="features section">
      <div className="container">
        <div className="row text-center">
          <h3>Преимущества</h3>
        </div>
        <div className="row">
          <div className="col-md-4 col-sm-6 feature text-center">
            <i className="fa fa-user-md fa-3x" />
            <div className="feature-content">
              <h5>Опытные врачи (1-й и высшей квалификационной категории)</h5>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 feature text-center">
            <i className="fa fa-heartbeat fa-3x" />
            <div className="feature-content">
              <h5>Современное оборудование отвечающее мировым стандартам</h5>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 feature text-center">
            <i className="fa fa-money fa-3x" />
            <div className="feature-content">
              <h5>Доступные цены</h5>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 col-sm-6 feature text-center">
            <i className="fa fa-clock-o fa-3x" />
            <div className="feature-content">
              <h5>Удобный график приема</h5>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 feature text-center">
            <i className="fa fa-car fa-3x" />
            <div className="feature-content">
              <h5>Удобная парковка</h5>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 feature text-center">
            <i className="fa fa-map-marker fa-3x" />
            <div className="feature-content">
              <h5>Нас легко найти и удобно к нам обратиться</h5>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
