import React from "react";

export const Prices = ({ prices = [] }) => {
  return (
    <>
      <section id="prices" class="section prices">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-xs-12 row text-center">
              <h3>Цены</h3>
            </div>
          </div>
          {
            prices.map((priceGroup) => {
              return <div className="row" key={priceGroup.id}>
                <div className="col-md-12 col-xs-12">
                  <table className="table table-condensed prices">
                    <thead>
                    <tr className="gray-line">
                      <th colSpan="2">
                        { priceGroup.category }
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                      priceGroup.services.map((service) => {
                        if('services' in service) {
                          return (
                            <>
                            <tr>
                              <td className="bold-text" colSpan="2">
                                {service.name}
                              </td>
                            </tr>
                            {
                              service.services.map((price) => {
                                return <tr>
                                  <td>{price.name}</td>
                                  <td>{price.price}</td>
                                </tr>
                              })
                            }
                          </>
                          )
                        } else {
                          return <tr>
                            <td>{service.name}</td>
                            <td>{service.price}</td>
                          </tr>
                        }
                      })
                    }
                    </tbody>
                  </table>
                </div>
              </div>
            })
          }
        </div>
      </section>
    </>
  );

  // return (
  //   <>
  //     <section id="prices" class="section prices">
  //       <div class="container">
  //         <div class="row">
  //           <div class="col-md-12 col-xs-12 row text-center">
  //             <h3>Цены</h3>
  //           </div>
  //         </div>
  //
  //         <div class="row">
  //           <div class="col-md-12 col-xs-12">
  //             <table class="table table-condensed prices">
  //               <thead>
  //                 <tr class="gray-line">
  //                   <th colspan="2">Консультация врача-специалиста:</th>
  //                 </tr>
  //               </thead>
  //               <tbody>
  //                 <tr>
  //                   <td> - Терапевтического профиля</td>
  //                   <td>22,25 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td> - Хирургического профиля</td>
  //                   <td>26,70 руб.</td>
  //                 </tr>
  //               </tbody>
  //             </table>
  //           </div>
  //         </div>
  //
  //         <div class="row">
  //           <div class="col-md-12 col-xs-12">
  //             <table class="table table-condensed prices">
  //               <thead>
  //                 <tr class="gray-line">
  //                   <th colspan="2">Услуги врача-офтальмолога:</th>
  //                 </tr>
  //               </thead>
  //               <tbody>
  //                 <tr>
  //                   <td> - Первичный прием</td>
  //                   <td>26,70 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td> - Повторный прием</td>
  //                   <td>22,25 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td class="bold-text" colspan="2">
  //                     Контактная коррекция зрения
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td>- подбор контактных линз (первичный)</td>
  //                   <td>29,30 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td class="bold-text" colspan="2">
  //                     Диагностические офтальмологические исследования
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td>
  //                     - исследование переднего отрезка глаза с помощью щелевой
  //                     лампы (биомикроскопия)
  //                   </td>
  //                   <td>14,89 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>
  //                     - измерение внутриглазного давления (тонометрия) (с
  //                     лидокаином)
  //                   </td>
  //                   <td>11,51 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>
  //                     - измерение внутриглазного давления (тонометрия) (с
  //                     тетракаином)
  //                   </td>
  //                   <td>12,02 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>- авторефрактокератометрия</td>
  //                   <td>11,32 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>- гониоскопия (с лидокаином)</td>
  //                   <td>15,34 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>- гониоскопия (с тетракаином)</td>
  //                   <td>16,36 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>
  //                     - осмотр глазного дна с фундус-линзой (с лидокаином)
  //                   </td>
  //                   <td>22,78 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>
  //                     - осмотр глазного дна с фундус-линзой (с тетракаином)
  //                   </td>
  //                   <td>23,80 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>- офтальмоскопия(исследование глазного дна)</td>
  //                   <td>14,22 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td class="bold-text" colspan="2">
  //                     Офтальмологические манипуляции
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td>- промывание слёзных путей (с лидокаином)</td>
  //                   <td>18,66 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>- промывание слёзных путей (с тетракаином)</td>
  //                   <td>19,68 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>- эпиляция ресниц (с лидокаином)</td>
  //                   <td>15,34 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>- эпиляция ресниц (с тетракаином)</td>
  //                   <td>16,36 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>- массаж век с тушированием (с лидокаином)</td>
  //                   <td>15,34 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>- массаж век с тушированием (с тетракаином)</td>
  //                   <td>16,36 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>- удаление инородного тела</td>
  //                   <td>11,33 руб.</td>
  //                 </tr>
  //               </tbody>
  //             </table>
  //           </div>
  //         </div>
  //
  //         <div class="row">
  //           <div class="col-md-12 col-xs-12">
  //             <table class="table table-condensed prices">
  //               <thead>
  //                 <tr class="gray-line">
  //                   <th colspan="2">
  //                     Выполнение массажных процедур механическим воздействием
  //                     руками:
  //                   </th>
  //                 </tr>
  //               </thead>
  //               <tbody>
  //                 <tr>
  //                   <td>
  //                     - массаж головы ( лобно-височной и затылочно-теменной
  //                     области)
  //                   </td>
  //                   <td>6,67 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>
  //                     - массаж лица (лобной, окологлазничной, верхне- и
  //                     нижнечелюстной области)
  //                   </td>
  //                   <td>6,67 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>- массаж шеи</td>
  //                   <td>6,67 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>
  //                     - массаж воротниковой зоны (задней поверхности шеи, спина
  //                     до уровня IV грудного позвонка, передней поверхности
  //                     грудной клетки до 2-го ребра)
  //                   </td>
  //                   <td>8,53 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>- массаж верхней конечности</td>
  //                   <td>8,53 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>
  //                     - массаж верхней конечности, надплечья и области лопатки
  //                   </td>
  //                   <td>10,28 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>
  //                     - массаж плечевого сустава (верхней трети плеча, области
  //                     плечевого сустава и надплечья одноимённой стороны)
  //                   </td>
  //                   <td>6,67 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>
  //                     - массаж локтевого сустава (верхней трети плеча, области
  //                     локтевого сустава и нижней трети плеча)
  //                   </td>
  //                   <td>6,67 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>
  //                     - массаж лучезапястного сустава (проксимального отдела
  //                     кисти, области лучезапястного сустава и предплечья)
  //                   </td>
  //                   <td>6,67 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>- массаж кисти и предплечья </td>
  //                   <td>6,67 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>
  //                     - массаж области грудной клетки (области передней
  //                     поверхности грудной клетки от передних границ надплечий до
  //                     рёберных дуг и области спины от VII до I поясничного
  //                     позвонка)
  //                   </td>
  //                   <td>11,97 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>
  //                     - массаж спины ( от VII шейного до I поясничного позвонка
  //                     и от левой до пра-вой средней аксиллярной линии, у детей -
  //                     включая пояснично-крестцовую область)
  //                   </td>
  //                   <td>8,53 руб.</td>
  //                 </tr>
  //
  //                 <tr>
  //                   <td>- массаж мышц передней брюшной стенки</td>
  //                   <td>6,67 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>
  //                     - массаж пояснично-крестцовой области (от I поясничного
  //                     позвонка до нижних ягодичных складок)
  //                   </td>
  //                   <td>6,67 руб.</td>
  //                 </tr>
  //
  //                 <tr>
  //                   <td>- сегментарный  массаж пояснично-крестцовой области</td>
  //                   <td>8,53 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>
  //                     - массаж спины и поясницы ( от VII шейного позвонка до
  //                     крестца и от левой до правой средней аксиллярной линии)
  //                   </td>
  //                   <td>10,28 руб.</td>
  //                 </tr>
  //
  //                 <tr>
  //                   <td>
  //                     - массаж шейно-грудного отдела позвоночника (области
  //                     задней поверхности шеи и области спины до первого
  //                     поясничного позвонка и от левой до правой задней и
  //                     аксиллярной линии)
  //                   </td>
  //                   <td>10,28 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>
  //                     - сегментарный массаж шейно-грудного отдела позвоночника
  //                   </td>
  //                   <td>13,88 руб.</td>
  //                 </tr>
  //
  //                 <tr>
  //                   <td>
  //                     - массаж области позвоночника (области задней поверхности
  //                     шеи, спины и пояснично-крестцовой области от левой до
  //                     правой задней аксиллярной линии) 
  //                   </td>
  //                   <td>11,97 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>- массаж нижней конечности</td>
  //                   <td>8,53 руб.</td>
  //                 </tr>
  //
  //                 <tr>
  //                   <td>
  //                     - массаж нижней конечности и поясницы ( области стопы,
  //                     голени, бедра, ягодичной и пояснично-крестцовой области)
  //                   </td>
  //                   <td>10,28 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>
  //                     - массаж тазобедренного сустава (верхней трети бедра,
  //                     области тазобедренного сустава и ягодичной области
  //                     одноимённой стороны)
  //                   </td>
  //                   <td>6,67 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>
  //                     - массаж коленного сустава ( верхней трети голени, области
  //                     коленного сустава и нижней трети бедра)
  //                   </td>
  //                   <td>6,67 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>
  //                     - массаж голеностопного сустава ( проксимального отдела
  //                     стопы, области голено-стопного сустава и нижней трети
  //                     голени)
  //                   </td>
  //                   <td>6,67 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>- массаж стопы и голени</td>
  //                   <td>6,67 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>
  //                     - общий массаж (у детей грудного и младшего дошкольного
  //                     возраста)
  //                   </td>
  //                   <td>13,88 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>- периостальный массаж</td>
  //                   <td>20,82 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>- точечный массаж</td>
  //                   <td>13,88 руб.</td>
  //                 </tr>
  //
  //                 <tr>
  //                   <td>- соединительно тканный массаж</td>
  //                   <td>17,32 руб.</td>
  //                 </tr>
  //               </tbody>
  //             </table>
  //           </div>
  //         </div>
  //
  //         <div class="row">
  //           <div class="col-md-12 col-xs-12">
  //             <table class="table table-condensed prices">
  //               <thead>
  //                 <tr class="gray-line">
  //                   <th colspan="2">Ульразвуковая диагностика:</th>
  //                 </tr>
  //               </thead>
  //               <tbody>
  //                 <tr>
  //                   <td class="bold-text" colspan="2">
  //                     Ультразвуковое исследование органов брюшной полости:
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td>- печень, желчный пузырь без определения функции</td>
  //                   <td>8,40 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>- печень, желчный пузырь с определением функции</td>
  //                   <td>14,39 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>- поджелудочная железа</td>
  //                   <td>8,40 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>- селезенка</td>
  //                   <td>5,57 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>- кишечник без заполнения жидкостью</td>
  //                   <td>5,57 руб.</td>
  //                 </tr>
  //
  //                 <tr>
  //                   <td class="bold-text" colspan="2">
  //                     Ультразвуковое исследование органов мочеполовой системы:
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td>- почки и надпочечники</td>
  //                   <td>11,56 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>- мочевой пузырь</td>
  //                   <td>5,57 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>- мочевой пузырь с определением остаточной мочи</td>
  //                   <td>8,43 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>- почки, надпочечники и мочевой пузырь</td>
  //                   <td>14,39 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>
  //                     - почки, надпочечники и мочевой пузырь с определением
  //                     остаточной мочи
  //                   </td>
  //                   <td>17,19 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>
  //                     - предстательная железа с мочевым пузырем и определением
  //                     остаточной мочи (трансабдоминально)
  //                   </td>
  //                   <td>14,39 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>- предстательная железа (трансректально)</td>
  //                   <td>14,84 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>- мошонка</td>
  //                   <td>8,58 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>
  //                     - матка и придатки с мочевым пузырем (трансабдоминально)
  //                   </td>
  //                   <td>11,58 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>- матка и придатки (трансвагинально)</td>
  //                   <td>12,21 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>- плод в I триместре до 11 недель беременности</td>
  //                   <td>11,63 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>- плод в I триместре с 11 до 14 недель беременности</td>
  //                   <td>17,13 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>- плод во II и III триместре беременности</td>
  //                   <td>17,25 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>
  //                     - органы брюшной полости и почки (печень и желчный пузырь
  //                     без определения функции, поджелудочная железа, селезенка,
  //                     почки и надпочечники, кишечник без заполнения жидкостью)
  //                   </td>
  //                   <td>26,70 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td class="bold-text" colspan="2">
  //                     Ультразвуковое исследование других органов:
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td>
  //                     <b>- легких и плевральной полости</b>
  //                   </td>
  //                   <td>25,26 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>
  //                     - щитовидная железа с лимфатическими поверхностными узлами
  //                   </td>
  //                   <td>11,58 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>
  //                     - молочные железы с лимфатическими поверхностными узлами
  //                   </td>
  //                   <td>14,39 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>- слюнные железы (или подчелюстные или околоушные)</td>
  //                   <td>5,88 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>- мягкие ткани</td>
  //                   <td>5,88 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>- суставы непарные</td>
  //                   <td>8,58 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>- суставы парные</td>
  //                   <td>11,69 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>- головной мозг новорожденного</td>
  //                   <td>11,58 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>- лимфатические узлы (одна область с обеих сторон)</td>
  //                   <td>5,91 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>- дуплексное сканирование сосудов плода и матки</td>
  //                   <td>14,28 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>
  //                     - ультразвуковая допплерография (УЗДГ одного артериального
  //                     бассейна (брахиоцефальных артерий или артерий верхних
  //                     конечностей или артерий нижних конечностей)
  //                   </td>
  //                   <td>17,19 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>
  //                     - ультразвуковая допплерография (УЗДГ) одного венозного
  //                     бассейна (брахиоцефальных вен или вен верхних конечностей
  //                     или вен нижних конечностей)
  //                   </td>
  //                   <td>17,19 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>
  //                     - дуплексное сканирование сосудов с цветным и
  //                     энергетическим допплером одного артериального или одного
  //                     венозного бассейна (брахиоцефальных сосудов или сосудов
  //                     верхних или нижних конечностей)
  //                   </td>
  //                   <td>22,79 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>
  //                     - дуплексное сканирование сосудов с цветным и
  //                     энергетическим допплером органов брюшной полости и
  //                     забрюшинного пространства
  //                   </td>
  //                   <td>22,79 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>
  //                     - дуплексное сканирование сосудов одного анатомического
  //                     региона
  //                   </td>
  //                   <td>11,58 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>- сердце / детское сердце </td>
  //                   <td>31,78 руб.</td>
  //                 </tr>
  //               </tbody>
  //             </table>
  //           </div>
  //         </div>
  //
  //         <div class="row">
  //           <div class="col-md-12 col-xs-12">
  //             <table class="table table-condensed prices">
  //               <thead>
  //                 <tr class="gray-line">
  //                   <th colspan="2">Акушерство и гинекология:</th>
  //                 </tr>
  //               </thead>
  //               <tbody>
  //                 <tr>
  //                   <td class="bold-text" colspan="2">
  //                     Консультация врача акушер-гинеколога (включая осмотр):
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td>- первичный прием</td>
  //                   <td>27,93 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>- повторный прием</td>
  //                   <td>22,36 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td class="bold-text" colspan="2">
  //                     Гинекологические манипуляции и процедуры:
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td>-медикаментозный аборт</td>
  //                   <td>186,42 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>-забор мазка на исследование</td>
  //                   <td>2,76 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>- кольпоцитология</td>
  //                   <td>2,73 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>- кольпоскопия простая</td>
  //                   <td>15,69 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>
  //                     - кольпоскопия расширенная с цитологией, биопсией и
  //                     соскобом из шейки матки
  //                   </td>
  //                   <td>30,60 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>
  //                     - кольпоскопия расширенная с цитологией и биопсией шейки
  //                     матки
  //                   </td>
  //                   <td>23,33 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>- кольпоскопия расширенная с цитологией</td>
  //                   <td>16,06 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>- лечебная процедура (введение лечебных тампонов)</td>
  //                   <td>4,44 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>- гинекологический массаж</td>
  //                   <td>9,61 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td class="bold-text" colspan="2">
  //                     Гинекологические манипуляции:
  //                   </td>
  //                 </tr>
  //                 <tr>
  //                   <td>- введение внутриматочного средства</td>
  //                   <td>13,49 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>- удаление внутриматочного средства</td>
  //                   <td>12,28 руб.</td>
  //                 </tr>
  //               </tbody>
  //             </table>
  //           </div>
  //         </div>
  //         <div class="row">
  //           <div class="col-md-12 col-xs-12">
  //             <table class="table table-condensed prices">
  //               <thead>
  //                 <tr class="gray-line">
  //                   <th colspan="2">Хирургические манипуляции</th>
  //                 </tr>
  //               </thead>
  //               <tbody>
  //                 <tr>
  //                   <td>- паравертебральная блокада</td>
  //                   <td>19,85 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>- мышечная блокада</td>
  //                   <td>15,85 руб.</td>
  //                 </tr>
  //               </tbody>
  //             </table>
  //           </div>
  //         </div>
  //         <div class="row">
  //           <div class="col-md-12 col-xs-12">
  //             <table class="table table-condensed prices">
  //               <thead>
  //                 <tr class="gray-line">
  //                   <th colspan="2">Взрослая психотерапия</th>
  //                 </tr>
  //               </thead>
  //               <tbody>
  //                 <tr>
  //                   <td>- первичный прием врача-психотерапевта (взрослый)</td>
  //                   <td>21,20 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>- повторный прием врача-психотерапевта (взрослый)</td>
  //                   <td>15,90 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>
  //                     - сеанс индивидуальной психотерапии невротических,
  //                     психосоматических и новеденческих расстройств
  //                   </td>
  //                   <td>50,00 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>
  //                     - сеанс коллективно-групповой психотерапии невротических,
  //                     психосоматических и новеденческих расстройств (цена для
  //                     одного пациента)
  //                   </td>
  //                   <td>8,48 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>
  //                     - сеанс коллективно-групповой психотерапии:
  //                     эмоционально-стрессовая психотерапия, пневмокатаренс (цена
  //                     для одного пациента)
  //                   </td>
  //                   <td>14,84 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>
  //                     - сеанс комплексной индивидуальной терапии невротических,
  //                     психосоматических и поведенческих расстройств с сочетанным
  //                     применением психотерапии и других методик: аппаратные
  //                     психотехнологии, музыкотерапия, ароматерапия и другое
  //                   </td>
  //                   <td>53,00 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>
  //                     - сеанс индивидуальной психотерапии зависимостей
  //                     (алкогольной, никотиновой, пищевой, игровой и других)
  //                   </td>
  //                   <td>50,00 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>
  //                     - сеанс коллективно-групповой эмоционально-стрессовой
  //                     психотерапии зависимостей (алкогольной, никотиновой,
  //                     пищевой, игровой и других) (цена для одного пациента)
  //                   </td>
  //                   <td>14,84 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>
  //                     - сеанс коллективно-групповой психотерапии зависимостей
  //                     (цена для одного пациента)
  //                   </td>
  //                   <td>31,80 руб.</td>
  //                 </tr>
  //                 <tr>
  //                   <td>- сеанс семейной психотерапии</td>
  //                   <td>31,80 руб.</td>
  //                 </tr>
  //               </tbody>
  //             </table>
  //           </div>
  //         </div>
  //       </div>
  //     </section>
  //   </>
  // );
};
