import React from "react";

const AppHeader = ({info}) => {
  return (
    <>
      <section className="banner" role="banner">
        <header id="header">
          <div className="header-content clearfix">
            <div className="col-md-10">
              <div className="row">
                <nav className="navigation" role="navigation">
                  <ul className="primary-nav">
                    <li>
                      <a href="#services">Услуги</a>
                    </li>
                    <li>
                      <a href="#about">О нас</a>
                    </li>
                    <li>
                      <a href="#features">Преимущества</a>
                    </li>
                    <li>
                      <a href="#doctors">Врачи</a>
                    </li>
                    <li>
                      <a href="#prices">Цены</a>
                    </li>
                    <li>
                      <a href="#contact">Контакты</a>
                    </li>
                    <li>
                      <a href="#form" className="btn btn-small magnific-popup">
                        Заказать звонок
                      </a>
                    </li>
                  </ul>
                </nav>
                <a href="#" className="nav-toggle">
                  Меню<span></span>
                </a>
              </div>
            </div>
          </div>
        </header>
        <div className="container no-gutter">
          <div className="col-md-12">
            <div className="banner-text text-left">
              <div className="row">
                <div className="col-md-12 text-center">
                  <div className="col-md-12">
                    <img
                      className="logo"
                      width="230"
                      src="images/logo2.png"
                      alt="Профздавмед"
                    />
                  </div>
                  <h1>
                    <span className="profzdravmed-lighten">Профздравмед</span>
                  </h1>
                  <p>современная частная клиника в Мозыре</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <ul>
                    <span>Свяжитесь с нами:</span>
                    <li>
                      <a href="tel:+375297396238">{info.phone[0]}</a>
                    </li>
                    <li>
                      <a href="tel:+375236202022">{info.phone[1]}</a>
                    </li>
                  </ul>
                </div>

                <div className="col-md-4">
                  <ul>
                    <span>График работы:</span>
                    <li>{info.schedule[0]}</li>
                    <li>{info.schedule[1]}</li>
                  </ul>
                </div>

                <div className="col-md-4">
                  <ul>
                    <span>Наш адрес:</span>
                    <li>{info.address}</li>
                  </ul>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 text-center">
                  <a href="#form" className="btn btn-large magnific-popup form">
                    Заказать звонок
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AppHeader;
