import React from "react";

export const About = ({info}) => {
  return (
    <section id="about" className="section about">
      <div className="container">
        <div className="row text-center">
          <h2>О нас</h2>
        </div>
        <p>
          {info.about}
        </p>
      </div>
      {/*<div className="container">*/}
      {/*  <div className="row no-gutter">*/}
      {/*    <div className="col-lg-3 col-md-3 col-sm-3 work text-center">*/}
      {/*      <a href="images/gallery/p1.jpg" className="work-box">*/}
      {/*        <img src="images/gallery/p1-thumb.jpg" alt />*/}
      {/*        <div className="overlay">*/}
      {/*          <div className="overlay-caption">*/}
      {/*            <h5>*/}
      {/*              Медицинский центр{" "}*/}
      {/*              <span className="profzdravmed-lighten">Профздравмед</span>*/}
      {/*            </h5>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      </a>*/}
      {/*    </div>*/}
      {/*    <div className="col-lg-3 col-md-3 col-sm-3 work text-center">*/}
      {/*      <a href="images/gallery/p2.jpg" className="work-box">*/}
      {/*        <img src="images/gallery/p2-thumb.jpg" alt />*/}
      {/*        <div className="overlay">*/}
      {/*          <div className="overlay-caption">*/}
      {/*            <h5>Ультразвуковой аппарат Aloka (Япония)</h5>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      </a>*/}
      {/*    </div>*/}
      {/*    <div className="col-lg-3 col-md-3 col-sm-3 work text-center">*/}
      {/*      <a href="images/gallery/p3.jpg" className="work-box">*/}
      {/*        <img src="images/gallery/p3-thumb.jpg" alt />*/}
      {/*        <div className="overlay">*/}
      {/*          <div className="overlay-caption">*/}
      {/*            <h5>*/}
      {/*              Директор медцентра, врач 1-й квалификационной категории*/}
      {/*              Сидоров Виталий Маратович*/}
      {/*            </h5>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      </a>*/}
      {/*    </div>*/}
      {/*    <div className="col-lg-3 col-md-6 col-sm-3 work text-center">*/}
      {/*      <a href="images/gallery/p4.jpg" className="work-box">*/}
      {/*        <img src="images/gallery/p4-thumb.jpg" alt />*/}
      {/*        <div className="overlay">*/}
      {/*          <div className="overlay-caption">*/}
      {/*            <h5>Офтальмологический кабинет</h5>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      </a>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </section>
  );
};
