import React from "react";

export const Doctors = ({doctors = []}) => {
  return (
    <section id="doctors" class="doctors section">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-xs-12 text-center">
            <h3>Врачи</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <table class="table table-condensed">
              {
                doctors.map((doctor) => {
                  return <>
                    <thead>
                    <tr class="gray-line">
                      <th colspan="2">{doctor.name}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>Специализация</td>
                      <td>{doctor.specialization}</td>
                    </tr>
                    <tr>
                      <td>Квалификация</td>
                      <td>{doctor.qualification}</td>
                    </tr>
                    <tr>
                      <td>Дополнительная информация</td>
                      <td>
                        {doctor.additionalInfo}
                      </td>
                    </tr>
                    </tbody>
                  </>
                })
              }
            </table>
          </div>
        </div>
      </div>
    </section>
  );
};
