import React, {useEffect, useState} from "react";

import Header from "../components/Header";
import Services from "../components/Services";
import About from "../components/About";
import Features from "../components/Features";
import Doctors from "../components/Doctors";
import Prices from "../components/Prices";
import Footer from "../components/Footer";
import ModalComponent from "../components/ModalComponent";
import {getDatabase, onValue, ref} from "firebase/database";
import {Box, CircularProgress} from "@mui/material";

export default function MainPage() {
  const [appData, setAppData] = useState(null);
  const database = getDatabase();
  const path = 'prod';

  useEffect(() => {
    const dbRef = ref(database, path);

    const unsubscribe = onValue(dbRef, (snapshot) => {
      const dataFromFirebase = snapshot.val();
      // console.log(dataFromFirebase)
      setAppData(dataFromFirebase);
    });

    return () => unsubscribe();
  }, []);

  if (appData) {
    return <>
      {/* <ModalComponent /> */}
      <Header info={appData.info}/>
      <Services />
      <About info={appData.info}/>
      <Features />
      <Doctors doctors={appData.doctors}/>
      <Prices prices={appData.services} />
      <Footer info={appData.info}/>
    </>
  } else {
    return <>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', 'height': '100vh'}}>
        <CircularProgress />
      </Box>
    </>
  }


}
