import React from "react";

export const Services = () => {
  return (
    <section id="services" className="section services text-center">
      <div className="container">
        <div className="row">
          <div className="col-md-4 col-sm-6">
            <div className="person">
              <h4>Терапия</h4>
              <div className="img-services">
                <img
                  src="images/services/terapia.png"
                  alt
                  className="img-responsive"
                />
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="person">
              <h4>УЗИ</h4>
              <div className="img-services">
                <img
                  src="images/services/uzi.png"
                  alt
                  className="img-responsive"
                />
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="person">
              <h4>Эндокринология</h4>
              <div className="img-services">
                <img
                  src="images/services/endokrinologia.png"
                  alt
                  className="img-responsive"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 col-sm-6">
            <div className="person">
              <h4>Педиатрия</h4>
              <div className="img-services">
                <img
                  src="images/services/pediatria.png"
                  alt
                  className="img-responsive"
                />
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="person">
              <h4>Офтальмология</h4>
              <div className="img-services">
                <img
                  src="images/services/oftalmolog.png"
                  alt
                  className="img-responsive"
                />
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="person">
              <h4>Массаж</h4>
              <div className="img-services">
                <img
                  src="images/services/massaj.png"
                  alt
                  className="img-responsive"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 col-sm-6">
            <div className="person">
              <h4>Психотерапия</h4>
              <div className="img-services">
                <img
                  src="images/services/psycho.png"
                  alt
                  className="img-responsive"
                />
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="person">
              <h4>Неврология</h4>
              <div className="img-services">
                <img
                  src="images/services/neuro.png"
                  alt
                  className="img-responsive"
                />
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="person">
              <h4>Гинекология</h4>
              <div className="img-services">
                <img
                  src="images/services/gynecology.png"
                  alt
                  className="img-responsive"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
