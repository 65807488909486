import React from "react";
import MainPage from "./pages/MainPage";
import { firebaseConfig } from "./firebase/config";
import { initializeApp } from "firebase/app";

function App() {
  initializeApp(firebaseConfig)



  return <MainPage />;
}

export default App;
